* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

body {
  scroll-behavior: smooth;
}
.homepage {
  width: 100%;
  height: 100%;
  /* background-color: rgb(238, 238, 238); */
  background-color: whitesmoke;
  position: relative;
  display: flex;
  flex-direction: column;
}

header {
  width: 100%;
  display: flex;
  padding-top: 1em;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
}

.burger-menu {
  display: none;
}

.responsive-nav {
  display: none;
}

.logo a {
  font-family: "Dancing Script", cursive;
  text-transform: capitalize;
  color: #028a0f;
  text-decoration: none;
}

nav {
  width: 30%;
  padding-top: 0.3em;
}

nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
}

nav ul li {
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: rgb(17, 17, 17);
  text-transform: uppercase;
  /* font-family: "Kanit", sans-serif; */
  font-family: "Fira Sans", sans-serif;
  font-size: 13px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  font-family: "Fira Sans", sans-serif;

  width: 20%;
}

select {
  height: 2em;
  margin-top: 0.7em;
  outline: none;
  border-color: #028a0f;
  border-radius: 5px;
  cursor: pointer;
}

.hire-me {
  width: 10em;
  height: 3em;
  border-radius: 20px;
  box-shadow: none;
  background-color: #028a0f;
  color: white;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

.hero-sec {
  margin-top: 4.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}

.first-hero {
  max-width: 40%;
}

.first-hero h4 {
  text-transform: capitalize;
  font-size: 22px;
  color: #028a0f;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 0.5em;
}

.first-hero h2 {
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 0.4em;
  font-size: 27px;
  color: rgb(17, 17, 17);
}

.first-hero p {
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5em;
}
.btn-div {
  margin-top: 2em;
  width: 50%;
  display: flex;
  gap: 0.8em;
}

.btn-div :nth-child(1) {
  width: 9em;
  height: 3em;
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
  outline: none;
  background-color: rgba(2, 138, 15, 0.9);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.btn-div :nth-child(2) {
  width: 9em;
  height: 3em;
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
  outline: none;
  border: 1px solid #028a0f;
  border-radius: 20px;
  cursor: pointer;
}
.second-hero {
  width: 40%;
  height: 100%;
  display: flex;
  gap: 5em;
  position: relative;
}

.second-hero img {
  width: 60%;
}

.hired {
  width: 8em;
  height: 7em;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: -6em;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.image img {
  position: absolute;
  top: -2em;
  right: -1em;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40%;
}

.hired-boox {
  margin-top: 0.4em;
  text-align: center;
}

.hired-boox p {
  font-family: "Fira Sans", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
}

.you-earned {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.5em 0;
  background-color: rgb(238, 238, 238);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

span {
  color: #028a0f;
  font-weight: lighter;
}

.acheivement-div {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-around;
  margin-top: 4em;
  padding-bottom: 3em;
}

.acheive-div {
  width: 18%;
  height: 100%;
  border: 1px dashed #028a0f;
  border-top-right-radius: 15px;
  position: relative;
}

.straight-line {
  border: 1px solid #028a0f;
}

.number h1 {
  text-align: center;
  margin-top: 0.5em;
  font-family: "Fira Sans", sans-serif;
  font-size: 32px;
}

.item h3 {
  text-align: center;
  margin-top: 1.3em;
  font-family: "Fira Sans", sans-serif;
  margin: 0.8em;
}

.about-flex {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  display: flex;
  gap: 12em;
}

.first-about-flex {
  max-width: 45%;
  padding-left: 2em;
}

.first-about-flex h1 {
  text-transform: capitalize;
  padding-top: 1.8em;
  font-family: "Fira Sans", sans-serif;
}

.first-about-flex p {
  margin-top: 1em;
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5em;
}

.second-about-flex {
  position: relative;
}

.second-about-flex img {
  /* position: absolute; */
  z-index: 5;
}
.second-about-flex svg {
  position: absolute;
  bottom: 3em;
  right: 0;
  width: 100%;
  z-index: 0;
}

.dot-div {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  position: absolute;
  top: 7em;
  right: 0;
  justify-content: space-around;
  column-gap: 0.7em;
  row-gap: 0.8em;
}

.dots {
  flex: 0 0 6%;

  height: 0.5em;
  border-radius: 50%;
  background-color: rgba(2, 138, 15, 0.2);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.features {
  width: 100%;
  height: 100%;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 3.5em;
  /* background-color: rgb(240, 240, 240); */
}

.features-head {
  text-align: center;
  margin-top: 4em;
  padding-top: 2em;
}

.features-head h1 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}

.features-head p {
  margin-top: 1em;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  line-height: 1.4em;
  font-size: 15px;
}

.feature-flex {
  margin-top: 2em;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.feature-box {
  width: 23%;
}

.icon-feature {
  text-align: center;
  margin-top: 0.3em;
}

.box-header {
  margin-top: 1em;
  text-align: center;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  color: rgb(26, 26, 26);
}

.para-feature {
  margin-top: 1em;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.4em;
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
}

.learn-more {
  text-align: center;
  margin-top: 3em;
  border: 1px solid black;
  cursor: pointer;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}

.learn-more a {
  color: #028a0f;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid black;
}

.services {
  width: 100%;
  height: 100%;
  padding-bottom: 5em;
}

.service-head {
  text-align: center;
  margin-top: 3em;
}

.service-head h1 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}

.service-head p {
  margin-top: 1em;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  line-height: 1.4em;
  font-size: 15px;
}

.services-sec {
  margin-top: 2em;
  position: relative;
  margin-left: 2em;
  margin-right: 2em;
}

.service-div {
  display: flex;
  flex-wrap: wrap;
  width: 10%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-around;
  column-gap: 0.7em;
  row-gap: 0.8em;
}

.service-sec-mobile {
  display: none;
}

.service-div-bottom {
  display: flex;
  flex-wrap: wrap;
  width: 10%;
  position: absolute;
  bottom: 0.2em;
  right: 0;
  justify-content: space-around;
  column-gap: 0.7em;
  row-gap: 0.8em;
}

.service-boxes {
  width: 70%;

  display: flex;
}

.service-box {
  flex: 0 0 40%;
  margin-left: 2em;
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.3em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  background-color: rgb(202, 202, 202);
  background-color: white;
  border-radius: 10px;
  z-index: 3;
}

.service-box-body {
  width: 80%;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.service-box-body h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.service-box-body p {
  margin-top: 0.5em;
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
}

.services-sec-2 {
  margin-top: 2em;
  position: relative;
  /* margin-left: 2em;
  margin-right: 2em; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.service-div-2 {
  display: flex;
  flex-wrap: wrap;
  width: 10%;
  position: absolute;
  bottom: -2em;
  right: 1.8em;
  justify-content: flex-end;
  column-gap: 0.7em;
  row-gap: 0.8em;
}

.project-sec {
  margin-top: 2em;
  width: 100%;
  height: 100%;
  padding-bottom: 6em;
}

.project-head {
  text-align: center;
}

.project-head h1,
p {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}

.project-head p {
  margin-top: 1em;
  font-size: 15px;
}

.projects-cat {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  column-gap: 4em;
}

.projects-cat .category {
  width: 10em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  color: black;
  background-color: rgb(223, 223, 223);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  cursor: pointer;
}

.green {
  background-color: #028a0f;
  color: white;
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  text-decoration: none;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 20px;
}

.project-body {
  text-align: center;
  margin-top: 6em;
}

.project-body p {
  font-size: 15px;
}

.technology {
  width: 100%;
  height: 100%;
  padding-bottom: 5em;
}

.tech-head {
  text-align: center;
}

.tech-head h1 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}
.tech-head p {
  margin-top: 1em;
  font-size: 15px;
}

.technologies-flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 3em;
  padding-left: 1em;
  padding-right: 1em;
  /* justify-content: space-between; */
  gap: 1.5em;
}

.tech-stack {
  width: 18%;
  max-height: 100%;
  background-color: white;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.tech-stack h3 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  margin-top: 0.5em;
}

.tech-stack p {
  font-size: 15px;
  margin-top: 0.8em;
}

.contact-page {
  width: 100%;
  padding-bottom: 3em;
}

.contact-head {
  margin-top: 1em;
  text-align: center;
}
.contact-head h1 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
}
.contact-head h3 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  margin-top: 0.5em;
}

.contact-head p {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  margin-top: 0.5em;
  font-size: 15px;
}

.contact-flex {
  display: flex;
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 3em;
  align-content: center;
}

.contact-info-div {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  margin-top: 2em;
}

.infos {
  display: flex;
  align-items: center;
  gap: 1em;
}

.other-details h4 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
}

.other-details p {
  font-size: 14px;
}

.contact-message-div {
  width: 40%;
}

.first-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.first-input input {
  width: 48%;
  border-radius: 5px;
  outline: none;
  border: 0.3px solid rgba(2, 138, 15, 0.3);
  height: 6vh;
  padding-left: 1em;
}

.second-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1em;
}

.second-input input {
  width: 48%;
  border-radius: 5px;
  outline: none;
  border: 0.3px solid rgba(2, 138, 15, 0.3);
  height: 6vh;
  padding-left: 1em;
}

.third-input {
  margin-top: 1em;
}

.btn-contact {
  width: 100%;
  text-align: right;
  margin-top: 1em;
}

.btn-contact button {
  width: 10em;
  height: 5vh;
  background-color: #028a0f;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  color: white;
  font-family: "Fira Sans", sans-serif;
  border: none;
}

footer {
  margin-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  gap: 8em;
  width: 100%;
}

.footer-logo h1 {
  text-transform: capitalize;
  font-family: "Dancing Script", cursive;
  color: #028a0f;
  font-size: 25px;
}
.footer-about p {
  font-size: 15px;
  margin-top: 0.5em;
}

.footer-icons {
  margin-top: 2em;
  display: flex;
  width: 15%;
  justify-content: space-between;
}

/* About Page */

.aboutMeflex {
  width: 100%;
  margin-top: 4.5em;
  padding-left: 1em;
  padding-right: 1em;
  gap: 2em;
  display: flex;
  height: 100%;
}

.firstAboutmeflex {
  width: 45%;
  height: 100%;
}

.firstAboutmeflex h1 {
  color: #028a0f;
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5em;
  font-size: 25px;
}

.firstAboutmeflex span {
  font-weight: bold;
  color: rgb(19, 19, 19);
  font-family: "Fira Sans", sans-serif;
}

.firstAboutmeflex p {
  margin-top: 1em;
  line-height: 1.6em;
  font-size: 16px;
}
.secondAboutMeflex {
  width: 45%;
  height: 100%;
}

.secondImageDiv {
  width: 70%;
  height: 60vh;
}

.secondAboutMeflex img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resume-btn {
  width: 100%;
  margin-top: 0.3em;
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.resume-btn :nth-child(1) {
  width: 85%;
  height: 3em;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  background-color: #028a0f;
}
.resume-btn :nth-child(2) {
  width: 15%;
  height: 3em;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
}

.resume-btn button:hover {
  outline: 1px solid gray;
}

.aboutEmail {
  margin-top: 1em;
  display: flex;
  gap: 0.5em;

  width: 50%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 0.5em;
  cursor: pointer;
}

.aboutEmail:hover {
  color: #028a0f;
}

.aboutEmail p {
  text-transform: none;
  font-size: 15px;
}

.softSkill {
  margin-top: 6em;
  margin-left: 1em;
  margin-bottom: 6em;
}

.softSkill h1 {
  font-family: "Fira Sans", sans-serif;
  font-size: 24px;
  margin-bottom: 0.3em;
}

.softSkill p {
  font-size: 16px;
}

.skills-div {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.skills {
  display: flex;
  gap: 1em;
  align-items: center;
}

.box-asterik {
  width: 0.3em;
  height: 0.3em;
  background-color: black;
}
.skills-details {
  display: flex;
  column-gap: 0.4em;
  align-items: center;
}
.skills-details span {
  color: black;
  font-weight: bold;
}
.skills-details p {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  line-height: 1.8em;
}

/* Project Page */

.projectPageHead {
  margin-top: 4.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 60%;
}

.projectPageHead h1 {
  text-transform: capitalize;
  font-family: "Fira Sans", sans-serif;
  font-size: 25px;
}

.projectPageHead p {
  margin-top: 1em;
  font-size: 15px;
  line-height: 1.5em;
}

.projectCarousel {
  margin-top: 7em;
  text-align: center;
}

.projectCarousel p {
  font-style: italic;
  font-size: 15px;
}

.hireme-div {
  position: fixed;
  display: flex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  top: 3em;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  left: 40%;
  z-index: 15;
  padding-bottom: 1em;
  padding-top: 1em;
  background-color: white;
  overflow: auto;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}

.hireme-div h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 20px;
  margin-top: 0.8em;
  text-align: center;
}

.hireme-div form label {
  font-family: "Fira Sans", sans-serif;
  font-size: 13px;
  margin-bottom: 0.5em;
}

.hireme-div input {
  height: 2.5em;
  border-radius: 0.4em;
  box-shadow: none;
  outline: none;
  padding-left: 0.4em;
  border: 1px solid grey;
  cursor: pointer;
}

.hireme-div form {
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
}

.in-1,
.in-2,
.in-3 {
  margin-bottom: 1em;
}

.request-btn {
  width: fit-content;
  padding: 0.8em;
  margin-top: 0.5em;
  font-family: "Fira Sans", sans-serif;
  color: white;
  background-color: #028a0f;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.hireme-div .textarea {
  height: 12em;
  width: 300px;
  border: 1px solid grey;
  overflow-x: hidden;
  padding-left: 0.5em;
  padding-top: 0.5em;
  outline: none;
  border-radius: 5px;
}

.font-cancel {
  background-color: #028a0f;
  width: fit-content;
  height: fit-content;
  padding: 0.35em 0.6em;
  border-radius: 50%;
}

@keyframes turner {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 4px solid black;
  border-top: 4px solid #028a0f;
  z-index: 15;
  border-radius: 50%;
  animation-name: turner;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.hireme-div {
  position: fixed;
  display: flex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  top: 3em;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  left: 40%;
  z-index: 15;
  padding-bottom: 1em;
  padding-top: 1em;
  background-color: white;
  overflow: auto;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}
@media only screen and (max-width: 767px) {
  /* Your styles for mobile phones */

  .loader {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 50%;
    left: 45%;
    border: 4px solid black;
    border-top: 4px solid #028a0f;
    z-index: 15;
    border-radius: 50%;
    animation-name: turner;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .hireme-div {
    position: fixed;
    display: flex;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    top: 6em;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    width: 95%;
    left: 0.6em;
    z-index: 15;
    padding-bottom: 1em;
    padding-top: 1em;
    background-color: white;
    overflow: auto;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    border: 1px solid #028a0f;
  }

  header {
    width: 100%;
    display: flex;
    padding-top: 1em;
    justify-content: space-between;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .responsive-nav {
    position: absolute;
    width: 0%;
    height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    transition: all ease-in 0.3s;
    right: 0;
  }

  .nav-bar {
    width: 95%;
    height: fit-content;
    background-color: white;
    z-index: 9;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
  }

  .first-nav-div {
    display: none;
    flex-direction: column;
    gap: 1em;
    margin-top: 5em;
    /* margin-left: 1.5em; */
  }
  .first-nav-div a {
    display: flex;
    gap: 0.5em;
    text-decoration: none;
  }

  .first-nav-div a p {
    color: rgba(0, 0, 0, 0.8);
  }

  .second-nav-div {
    margin-top: 3em;
    /* margin-left: 1.5em; */
    display: none;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 1em;
    transition: all ease-in;
  }

  .second-nav-div a {
    flex: 0 0 45%;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }

  .hire-me-div {
    width: 100%;
    margin-top: 2em;
  }

  .hire-me-div button {
    width: 100%;
    height: 3em;
    background-color: #028a0f;
    color: white;
    font-family: "Fira Sans", sans-serif;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    border: none;
    font-size: 16px;
  }
  .burger-menu {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    position: absolute;
    z-index: 10;
    top: 1.5em;
    right: 1.2em;
  }

  .div-1 {
    width: 1.8em;
    height: 0.2em;
    background-color: rgba(2, 138, 15, 0.8);
    border-radius: 5px;
    transition: all ease-in 0.3s;
  }

  .one-div {
    transform: translateX(-0.5em) rotate(130deg);
    width: 1em;
  }

  .logo a {
    font-family: "Dancing Script", cursive;
    text-transform: capitalize;
    color: #028a0f;
    text-decoration: none;
    font-size: 18px;
    position: absolute;
    z-index: 9;
  }

  header nav {
    width: 40%;
    padding-top: 0.3em;
    display: none;
  }

  nav ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* width: 100%; */
  }

  nav ul li {
    list-style: none;
  }

  nav ul li a {
    text-decoration: none;
    color: rgb(17, 17, 17);
    text-transform: uppercase;
    /* font-family: "Kanit", sans-serif; */
    font-family: "Fira Sans", sans-serif;
    font-size: 13px;
  }

  .buttons {
    display: none;
  }

  /* select {
    height: 2em;
    margin-top: 0.7em;
    outline: none;
    border-color: #028a0f;
    border-radius: 5px;
    cursor: pointer;
  } */

  /* .hire-me {
    width: 10em;
    height: 3em;
    border-radius: 20px;
    box-shadow: none;
    background-color: #028a0f;
    color: white;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    border: none;
    cursor: pointer;
  } */

  .hero-sec {
    margin-top: 6em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding-left: 1.5em;
    padding-right: 1.5em;
    row-gap: 2em;
  }

  .first-hero {
    max-width: 100%;
  }

  .first-hero h4 {
    text-transform: capitalize;
    font-size: 22px;
    color: #028a0f;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0.5em;
  }

  .first-hero h2 {
    text-transform: uppercase;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0.4em;
    font-size: 27px;
    color: rgb(17, 17, 17);
  }

  .first-hero p {
    font-family: "Fira Sans", sans-serif;
    line-height: 1.8em;
    font-size: 16px;
  }

  .btn-div {
    margin-top: 2em;
    width: 100%;
    display: flex;
    gap: 0.8em;
  }

  .btn-div :nth-child(1) {
    width: 11em;
    height: 3.2em;
    font-family: "Fira Sans", sans-serif;
    text-transform: capitalize;
    outline: none;
    background-color: rgba(2, 138, 15, 0.9);
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
  }

  .btn-div :nth-child(2) {
    width: 11em;
    height: 3.2em;
    font-family: "Fira Sans", sans-serif;
    text-transform: capitalize;
    outline: none;
    border: 1px solid #028a0f;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
  }
  .second-hero {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5em;
    position: relative;
  }

  .second-hero img {
    width: 75%;
  }

  .hired {
    width: 8em;
    height: 7em;
    border-radius: 5px;
    position: absolute;
    left: 12em;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .image img {
    position: absolute;
    top: -2em;
    right: -1em;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40%;
  }

  .hired-boox {
    margin-top: 0.4em;
    text-align: center;
  }

  .hired-boox p {
    font-family: "Fira Sans", sans-serif;
    text-transform: capitalize;
    font-size: 14px;
  }

  .you-earned {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
    padding: 0.5em 0;
    background-color: rgb(238, 238, 238);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  span {
    color: #028a0f;
    font-weight: lighter;
  }

  .acheivement-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    margin-top: 5em;
    padding-bottom: 3em;
    padding-left: 3em;
    padding-right: 3em;
    row-gap: 4em;
  }

  .acheive-div {
    width: 100%;
    height: 100%;
    border: 2px dashed #028a0f;
    border-top-right-radius: 15px;
    position: relative;
  }

  .straight-line {
    border: 1px solid #028a0f;
  }

  .number h1 {
    text-align: center;
    margin-top: 0.5em;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }

  .item h3 {
    text-align: center;
    margin-top: 1.3em;
    font-family: "Fira Sans", sans-serif;
    margin: 0.8em;
  }

  .about-flex {
    width: 100%;
    height: 100%;
    background-color: rgb(238, 238, 238);
    display: block;
  }

  .first-about-flex {
    max-width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }

  .first-about-flex h1 {
    text-transform: capitalize;
    padding-top: 1.8em;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }

  .first-about-flex p {
    margin-top: 1em;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.8em;
  }

  .second-about-flex {
    position: relative;
    display: none;
  }

  .second-about-flex img {
    /* position: absolute; */
    z-index: 5;
  }
  .second-about-flex svg {
    position: absolute;
    bottom: 3em;
    right: 0;
    width: 100%;
    z-index: 0;
  }

  .dot-div {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    position: absolute;
    top: 7em;
    right: 0;
    justify-content: space-around;
    column-gap: 0.7em;
    row-gap: 0.8em;
    display: none;
  }

  .dots {
    flex: 0 0 6%;

    height: 0.5em;
    border-radius: 50%;
    background-color: rgba(2, 138, 15, 0.2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .features {
    width: 100%;
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 3.5em;
    /* background-color: rgb(240, 240, 240); */
  }

  .features-head {
    text-align: center;
    margin-top: 2em;
    padding-top: 2em;
  }

  .features-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }

  .features-head p {
    margin-top: 1em;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.4em;
    font-size: 15px;
  }

  .feature-flex {
    margin-top: 2em;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    row-gap: 3em;
  }

  .feature-box {
    width: 100%;
  }

  .icon-feature {
    text-align: center;
    margin-top: 0.3em;
  }

  .box-header {
    margin-top: 1em;
    text-align: center;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    color: rgb(26, 26, 26);
  }

  .para-feature {
    margin-top: 1em;
    text-align: center;
    text-transform: capitalize;
    line-height: 1.4em;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
  }

  .learn-more {
    text-align: center;
    margin-top: 3em;

    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }

  .learn-more a {
    color: #028a0f;
    font-size: 16px;
  }

  .services {
    width: 100%;
    height: 100%;
    padding-bottom: 5em;
    padding-right: 1em;
    padding-left: 1em;
  }

  .service-head {
    text-align: center;
    margin-top: 3em;
  }

  .service-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }

  .service-head p {
    margin-top: 1em;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.4em;
    font-size: 15px;
  }

  .services-sec {
    margin-top: 2em;
    position: relative;
    margin-left: 0em;
    margin-right: 0em;
    border: 1px solid black;
    display: none;
  }
  .service-sec-mobile {
    margin-top: 1em;
    position: relative;
    margin-left: 1em;
    margin-right: 1em;
    padding-left: 0em;
    display: block;
  }

  .service-div {
    display: none;
  }

  .service-boxes-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 1.3em;
  }

  .service-box-mobile {
    /* flex: 0 0 40%; */
    margin-top: 2.5em;
    display: flex;
    justify-content: center;
    height: 25vh;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.3em;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: rgb(202, 202, 202);
    background-color: white;
    border-radius: 10px;

    z-index: 3;
  }

  .service-box-body-mobile {
    width: 100%;
  }
  .service-box-body-mobile h2 {
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
  }

  .service-box-body-mobile p {
    margin-top: 0.5em;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
  }

  .services-sec-2 {
    margin-top: 2em;
    position: relative;
    margin-left: 0em;
    margin-right: 0em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    display: none;
  }

  .service-div-2 {
    display: flex;
    flex-wrap: wrap;
    width: 10%;
    position: absolute;
    bottom: -2em;
    right: 1.8em;
    justify-content: flex-end;
    column-gap: 0.7em;
    row-gap: 0.8em;
    display: none;
  }

  .project-sec {
    margin-top: 2em;
    width: 100%;
    height: 100%;
    padding-bottom: 6em;
  }

  .project-head {
    text-align: center;
  }

  .project-head h1 {
    font-size: 27px;
  }
  .project-head h1,
  p {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }

  .project-head p {
    margin-top: 1em;
    font-size: 15px;
  }

  .projects-cat {
    margin-top: 2em;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    column-gap: 0.5em;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    justify-content: flex-start;
    overflow-x: auto;
    padding-top: 1em;
    padding-bottom: 1em;
    /* justify-content: space-between;
    column-gap: 0.5em;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* margin-left: 1em;
    margin-right: 1em; 
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    border: 1px solid black; */
  }

  .projects-cat .category {
    flex: 0 0 40%;
    /* scroll-snap-align: center; */
    /* flex: 0 0 40%;
    width: 0em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    color: black;
    background-color: rgb(223, 223, 223);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    cursor: pointer;
    scroll-snap-align: none; */
  }

  .green {
    background-color: #028a0f;
    color: white;
    flex: 0 0 40%;
    width: 20em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 20px;
  }

  .project-body {
    text-align: center;
    margin-top: 6em;
  }

  .project-body p {
    font-size: 15px;
  }

  .technology {
    width: 100%;
    height: 100%;
    padding-bottom: 5em;
  }

  .tech-head {
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }

  .tech-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }
  .tech-head p {
    margin-top: 1em;
    font-size: 15px;
  }

  .technologies-flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3em;
    padding-left: 2em;
    padding-right: 2em;
    justify-content: center;
    row-gap: 2em;
  }

  .tech-stack {
    flex: 0 0 25%;
    height: 100%;
    background-color: transparent;
    text-align: center;
    box-shadow: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 5px;
    padding-left: 2em;
    padding-right: 2em;
  }

  .tech-stack h3 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
    display: none;
  }

  .tech-stack p {
    font-size: 15px;
    margin-top: 0.8em;
    display: none;
  }

  .contact-page {
    width: 100%;
    padding-bottom: 3em;
  }

  .contact-head {
    margin-top: 1em;
    text-align: center;
  }
  .contact-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 27px;
  }
  .contact-head h3 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
  }

  .contact-head p {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
    font-size: 15px;
  }

  .contact-flex {
    display: flex;
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 3em;
    align-content: center;
    flex-direction: column-reverse;
  }

  .contact-info-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    margin-top: 2em;
  }

  .infos {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .other-details h4 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-weight: 300;
    font-size: 15px;
  }

  .other-details p {
    font-size: 14px;
  }

  .contact-message-div {
    width: 100%;
  }

  .first-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 0.8em;
  }

  .first-input input {
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 0.3px solid rgba(2, 138, 15, 0.3);
    height: 6vh;
    padding-left: 1em;
  }

  .second-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1em;
    flex-direction: column;
    row-gap: 0.5em;
  }

  .second-input input {
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 0.3px solid rgba(2, 138, 15, 0.3);
    height: 6vh;
    padding-left: 1em;
  }

  .third-input {
    margin-top: 1em;
  }

  .btn-contact {
    width: 100%;
    text-align: right;
    margin-top: 1em;
  }

  .btn-contact button {
    width: 10em;
    height: 5vh;
    background-color: #028a0f;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    color: white;
    font-family: "Fira Sans", sans-serif;
    border: none;
  }

  footer {
    margin-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2em;
    width: 100%;
  }

  .footer-logo h1 {
    text-transform: capitalize;
    font-family: "Dancing Script", cursive;
    color: #028a0f;
    font-size: 25px;
  }
  .footer-about p {
    font-size: 15px;
    margin-top: 0.5em;
  }

  .footer-icons {
    margin-top: 0em;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  /* About Page */

  .aboutMeflex {
    width: 100%;
    margin-top: 5em;
    padding-left: 1em;
    padding-right: 1em;
    gap: 6em;
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
  }

  .firstAboutmeflex {
    width: 100%;
    height: 100%;
  }

  .firstAboutmeflex h1 {
    color: #028a0f;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.5em;
    font-size: 24px;
  }

  .firstAboutmeflex span {
    font-weight: bold;
    color: rgb(19, 19, 19);
    font-family: "Fira Sans", sans-serif;
  }

  .firstAboutmeflex p {
    margin-top: 1em;
    line-height: 1.6em;
    font-size: 16px;
  }
  .secondAboutMeflex {
    width: 100%;
    height: 100%;
  }

  .secondImageDiv {
    width: 100%;
    height: 40vh;
  }

  .secondAboutMeflex img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .resume-btn {
    width: 100%;
    margin-top: 0.3em;
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  .resume-btn :nth-child(1) {
    width: 85%;
    height: 3em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    background-color: #028a0f;
    font-size: 16px;
  }
  .resume-btn :nth-child(2) {
    width: 15%;
    height: 3em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-top: 1.8em;
    padding-bottom: 1.8em;
    cursor: pointer;
  }

  .resume-btn button:hover {
    outline: 1px solid gray;
  }

  .aboutEmail {
    margin-top: 0.4em;
    display: flex;
    gap: 0.5em;
    display: none;
    width: 50%;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    padding-left: 0.5em;
    cursor: pointer;
  }

  .aboutEmail:hover {
    color: #028a0f;
  }

  .aboutEmail p {
    text-transform: none;
    font-size: 17px;
    font-family: "Fira Sans", sans-serif;
  }

  .softSkill {
    margin-top: 4em;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 4em;
  }

  .softSkill h1 {
    font-family: "Fira Sans", sans-serif;
    font-size: 24px;
    margin-bottom: 0.3em;
  }

  .softSkill p {
    font-size: 16px;
  }

  .skills-div {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .skills {
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .box-asterik {
    width: 0.3em;
    height: 0.3em;
    background-color: black;
  }
  .skills-details {
    display: flex;
    column-gap: 0.4em;
    align-items: center;
  }
  .skills-details span {
    color: black;
    font-weight: bold;
  }
  .skills-details p {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    line-height: 1.8em;
  }

  /* Project Page */

  .projectPageHead {
    margin-top: 5.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    width: 100%;
  }

  .projectPageHead h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 25px;
  }

  .projectPageHead p {
    margin-top: 1em;
    font-size: 15px;
    line-height: 1.5em;
  }

  .projectCarousel {
    margin-top: 7em;
    text-align: center;
  }

  .projectCarousel p {
    font-style: italic;
    font-size: 15px;
  }
}

/* Tablets (portrait and landscape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles for tablets */

  header {
    width: 100%;
    display: flex;
    padding-top: 1em;
    justify-content: space-between;
    padding-left: 2em;
    padding-right: 2em;
  }

  .responsive-nav {
    position: absolute;
    width: 0%;
    height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    transition: all ease-in 0.3s;
    right: 0;
  }

  .hireme-div {
    position: fixed;
    display: flex;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    top: 0.5em;
    flex-direction: column;
    padding-left: 2em;
    padding-right: 2em;
    left: 30%;
    z-index: 15;
    padding-bottom: 1em;
    padding-top: 1em;
    background-color: white;
    overflow: auto;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    border: 1px solid #028a0f;
  }

  .nav-bar {
    width: 95%;
    height: fit-content;
    background-color: white;
    z-index: 9;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
  }

  .first-nav-div {
    display: none;
    flex-direction: column;
    gap: 1em;
    margin-top: 5em;
    /* margin-left: 1.5em; */
  }
  .first-nav-div a {
    display: flex;
    gap: 0.5em;
    text-decoration: none;
  }

  .first-nav-div a p {
    color: rgba(0, 0, 0, 0.8);
  }

  .second-nav-div {
    margin-top: 3em;
    /* margin-left: 1.5em; */
    display: none;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 1em;
    transition: all ease-in;
  }

  .second-nav-div a {
    flex: 0 0 45%;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }

  .div-1 {
    width: 1.8em;
    height: 0.2em;
    background-color: rgba(2, 138, 15, 0.8);
    border-radius: 5px;
    transition: all ease-in 0.3s;
  }

  .one-div {
    transform: translateX(-0.5em) rotate(130deg);
    width: 1em;
  }

  .hire-me-div {
    width: 100%;
    margin-top: 2em;
  }

  .hire-me-div button {
    width: 100%;
    height: 3em;
    background-color: #028a0f;
    color: white;
    font-family: "Fira Sans", sans-serif;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    border: none;
    font-size: 16px;
  }
  .burger-menu {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    position: absolute;
    z-index: 10;
    top: 1.5em;
    right: 1.2em;
  }

  .logo a {
    font-family: "Dancing Script", cursive;
    text-transform: capitalize;
    color: #028a0f;
    text-decoration: none;
    font-size: 20px;
  }

  nav {
    width: 40%;
    padding-top: 0.3em;
    display: none;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    font-family: "Fira Sans", sans-serif;
    display: none;
    width: 20%;
  }

  .hero-sec {
    margin-top: 3.5em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
  }

  .hero-sec img {
    display: none;
  }

  .hired {
    display: none;
  }

  .first-hero {
    max-width: 100%;
  }

  .first-hero h4 {
    text-transform: capitalize;
    font-size: 22px;
    color: #028a0f;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0.5em;
  }

  .first-hero h2 {
    text-transform: uppercase;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0.4em;
    font-size: 27px;
    color: rgb(17, 17, 17);
  }

  .first-hero p {
    font-family: "Fira Sans", sans-serif;
    line-height: 1.5em;
  }
  .btn-div {
    margin-top: 2em;
    width: 50%;
    display: flex;
    gap: 0.8em;
  }

  .btn-div :nth-child(1) {
    width: 9em;
    height: 3em;
    font-family: "Fira Sans", sans-serif;
    text-transform: capitalize;
    outline: none;
    background-color: rgba(2, 138, 15, 0.9);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  .btn-div :nth-child(2) {
    width: 9em;
    height: 3em;
    font-family: "Fira Sans", sans-serif;
    text-transform: capitalize;
    outline: none;
    border: 1px solid #028a0f;
    border-radius: 20px;
    cursor: pointer;
  }
  .second-hero {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5em;
    position: relative;
    justify-content: center;
  }

  .second-hero img {
    width: 30%;
  }

  .hired {
    width: 8em;
    height: 7em;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 25em;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .acheivement-div {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    margin-top: 4em;
    padding-bottom: 3em;
    flex-wrap: wrap;
    row-gap: 2.5em;
  }

  .acheive-div {
    width: 50%;
    flex: 0 0 45%;
    height: 100%;
    border: 2px dashed #028a0f;
    border-top-right-radius: 15px;
    position: relative;
  }

  .about-flex {
    width: 100%;
    height: 100%;
    background-color: rgb(238, 238, 238);
    display: flex;
    gap: 12em;
    padding-bottom: 1em;
  }

  .first-about-flex {
    max-width: 45%;
    padding-left: 2em;
  }

  .btn-div {
    width: 100%;
  }

  .features {
    width: 100%;
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 3.5em;
    /* background-color: rgb(240, 240, 240); */
  }

  .features-head {
    text-align: center;
    margin-top: 4em;
    padding-top: 1em;
  }

  .features-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }

  .features-head p {
    margin-top: 1em;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.4em;
    font-size: 15px;
  }

  .feature-flex {
    margin-top: 2em;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5em;
  }

  .feature-box {
    width: 23%;
    flex: 0 0 45%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .icon-feature {
    text-align: center;
    margin-top: 0.3em;
  }

  .box-header {
    margin-top: 1em;
    text-align: center;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    color: rgb(26, 26, 26);
  }

  .para-feature {
    margin-top: 1em;
    text-align: center;
    text-transform: capitalize;
    line-height: 1.4em;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
  }

  .learn-more {
    text-align: center;
    margin-top: 3em;

    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }

  .learn-more a {
    color: #028a0f;
    font-size: 16px;
  }

  .services {
    width: 100%;
    height: 100%;
    padding-bottom: 5em;
  }

  .services-sec {
    margin-top: 2em;
    position: relative;
    margin-left: 2em;
    margin-right: 2em;
  }

  .service-div {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-around;
    column-gap: 0.7em;
    row-gap: 0.8em;
  }

  .service-boxes {
    width: 100%;

    display: flex;
  }

  .service-box {
  }

  .service-div-2 {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    position: absolute;
    bottom: -2em;
    right: 1.8em;
    justify-content: flex-end;
    column-gap: 0.7em;
    row-gap: 0.8em;
  }

  .project-sec {
    margin-top: 2em;
    width: 100%;
    height: 100%;
    padding-bottom: 6em;
  }

  .project-head {
    text-align: center;
  }

  .project-head h1,
  p {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }

  .project-head p {
    margin-top: 1em;
    font-size: 15px;
  }

  .projects-cat {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    column-gap: 1.8em;
  }

  .projects-cat .category {
    width: 10em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    color: black;
    background-color: rgb(223, 223, 223);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    cursor: pointer;
  }

  .green {
    background-color: #028a0f;
    color: white;
    width: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-size: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 20px;
  }

  .project-body {
    text-align: center;
    margin-top: 6em;
  }

  .project-body p {
    font-size: 15px;
  }

  .technology {
    width: 100%;
    height: 100%;
    padding-bottom: 5em;
  }

  .tech-head {
    text-align: center;
  }

  .tech-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }
  .tech-head p {
    margin-top: 1em;
    font-size: 15px;
  }

  .technologies-flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3em;
    padding-left: 1em;
    padding-right: 1em;
    justify-content: center;
    column-gap: 0.8em;
    row-gap: 1.5em;
  }

  .tech-stack {
    width: 20%;
    flex: 0 0 25%;
    max-height: 100%;
    background-color: white;
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }

  .tech-stack h3 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
  }

  .tech-stack p {
    font-size: 15px;
    margin-top: 0.8em;
  }

  .contact-page {
    width: 100%;
    padding-bottom: 3em;
  }

  .contact-head {
    margin-top: 1em;
    text-align: center;
  }
  .contact-head h1 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
  }
  .contact-head h3 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
  }

  .contact-head p {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5em;
    font-size: 15px;
  }

  .contact-flex {
    display: flex;
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 3em;
    align-content: center;
    flex-direction: column-reverse;
  }

  .contact-info-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    margin-top: 2em;
  }

  .infos {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .other-details h4 {
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    font-weight: 300;
    font-size: 15px;
  }

  .other-details p {
    font-size: 14px;
  }

  .contact-message-div {
    width: 100%;
  }

  .first-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .first-input input {
    width: 48%;
    border-radius: 5px;
    outline: none;
    border: 0.3px solid rgba(2, 138, 15, 0.3);
    height: 6vh;
    padding-left: 1em;
  }

  .second-input {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1em;
  }

  .second-input input {
    width: 48%;
    border-radius: 5px;
    outline: none;
    border: 0.3px solid rgba(2, 138, 15, 0.3);
    height: 6vh;
    padding-left: 1em;
  }

  .third-input {
    margin-top: 1em;
  }

  .btn-contact {
    width: 100%;
    text-align: right;
    margin-top: 1em;
  }

  .btn-contact button {
    width: 10em;
    height: 5vh;
    background-color: #028a0f;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    color: white;
    font-family: "Fira Sans", sans-serif;
    border: none;
  }

  footer {
    margin-top: 3em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    width: 100%;
  }

  .footer-logo h1 {
    text-transform: capitalize;
    font-family: "Dancing Script", cursive;
    color: #028a0f;
    font-size: 25px;
  }
  .footer-about p {
    font-size: 15px;
    margin-top: 0.5em;
  }

  .footer-icons {
    margin-top: 2em;
    display: flex;
    width: 25%;
    justify-content: space-between;
  }

  .aboutMeflex {
    width: 100%;
    margin-top: 4.5em;
    padding-left: 1em;
    padding-right: 1em;
    gap: 2em;
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    row-gap: 10em;
  }

  .firstAboutmeflex {
    width: 100%;
    height: 100%;
  }

  .firstAboutmeflex h1 {
    color: #028a0f;
    font-family: "Fira Sans", sans-serif;
    line-height: 1.5em;
    font-size: 25px;
  }

  .firstAboutmeflex span {
    font-weight: bold;
    color: rgb(19, 19, 19);
    font-family: "Fira Sans", sans-serif;
  }

  .firstAboutmeflex p {
    margin-top: 1em;
    line-height: 1.6em;
    font-size: 16px;
  }
  .secondAboutMeflex {
    width: 100%;
    height: 100%;
  }

  .secondImageDiv {
    width: 100%;
    height: 60vh;
  }

  .secondAboutMeflex img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .resume-btn {
    width: 100%;
    margin-top: 0.3em;
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  .resume-btn :nth-child(1) {
    width: 85%;
    height: 4em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    font-family: "Fira Sans", sans-serif;
    background-color: #028a0f;
    font-size: 16px;
  }
  .resume-btn :nth-child(2) {
    width: 15%;
    height: 6em;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    padding-top: 1em;
    padding-bottom: 1em;
    cursor: pointer;
  }

  .projectPage {
    width: 100%;
  }

  .projectPageHead {
    width: 100%;
  }
}
